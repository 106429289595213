import api from "@/api";
import headers from '../../assets/js/headers'

// 获取网关型号列表总数
export const getGatewayTypeList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimitCount').pass_token,
            "time": headers.headers('getListByLimitCount').timestamp
        },
        url: '/product/getListByLimitCount',
        method: 'GET',
        params: data
    })
}


// 获取网关型号某页列表
export const getPageGatewayTypeList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/product/getListByLimit',
        method: 'GET',
        params: data
    })
}

// 根据网关PID查询
export const gatewayQuery = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getByCode').pass_token,
            "time": headers.headers('getByCode').timestamp
        },
        url: '/product/getByCode',
        method: 'GET',
        params: data
    })
}


// 新增，编辑，删除 网关型号
export const saveGatewayTypeList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/product/save',
        method: 'POST',
        data: data
    })
}
