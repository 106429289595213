import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'


import Login from '../pages/login/login'
import Admin from "@/pages/admin/admin";
import Customer from "@/pages/customer/customer";
import GatewayType from "@/pages/gatewayType/gatewayType";
import IOTPatform from "@/pages/IOTPatform/IOTPatform";
import UpgradeFile from "@/pages/upgradeFile/upgradeFile";
import Monitoring from "@/pages/monitoring/monitoring";
import Administrator from "@/pages/administrator/administrator";

// 路由文件
const routes = [
// 路由的默认路径
    {
        path: '/',
        redirect: "/admin"
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/admin',
        redirect: "/admin/customer",
        meta: {
            requireAuth: true
        },
        component: Admin,
        children: [
            {
                path: '/admin/customer',
                meta: {
                    requireAuth: true
                },
                component: Customer
            },
            {
                path: '/admin/gatewayType',
                meta: {
                    requireAuth: true
                },
                component: GatewayType,
            },
            {
                path: '/admin/IOTPatform',
                meta: {
                    requireAuth: true
                },
                component: IOTPatform
            },
            {
                path: '/admin/upgradeFile',
                meta: {
                    requireAuth: true,
                    keepAlive: true
                },
                component: UpgradeFile,
            },
            {
                path: '/admin/monitoring',
                meta: {
                    requireAuth: true
                },
                component: Monitoring,
            },
            {
                path: '/admin/administrator',
                meta: {
                    requireAuth: true
                },
                component: Administrator,
            }
        ]
    }
]


const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})
export default router;