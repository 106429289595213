<template>
  <div class="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>
/*.el-dialog {*/
/*  min-width: 760px;*/
/*}*/

body, p, h1, h2, h3, h4 {
  margin: 0;
}

ul li, ol li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

thead tr {
  color: #6f7175;
  font-weight: 100;
}

/*分页*/
.el-pagination {
  margin-top: 20px;
  width: 100%;
  justify-content: center;
}
</style>
