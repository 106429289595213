import api from "@/api";
import headers from '../../assets/js/headers'

// 获取设备型号列表总数
export const getEquipmentList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimitCount').pass_token,
            "time": headers.headers('getListByLimitCount').timestamp
        },
        url: '/model/getListByLimitCount',
        method: 'GET',
        params: data
    })
}


// 获取设备型号某页列表
export const getPageEquipmentList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/model/getListByLimit',
        method: 'GET',
        params: data
    })
}

// 根据设备型号PID查询
export const equipmentQuery = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getByCode').pass_token,
            "time": headers.headers('getByCode').timestamp
        },
        url: '/model/getByCode',
        method: 'GET',
        params: data
    })
}


// 新增，编辑，删除 设备型号
export const saveEquipmentList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/model/save',
        method: 'POST',
        data: data
    })
}


// 获取物模型列表总数
export const getModelList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimitCount').pass_token,
            "time": headers.headers('getListByLimitCount').timestamp
        },
        url: '/model_dp/getListByLimitCount',
        method: 'GET',
        params: data
    })
}


// 获取物模型某页列表
export const getPageModelList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/model_dp/getListByLimit',
        method: 'GET',
        params: data
    })
}


// 根据dpid查询
export const dpidQuery = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getByModelAndDpid').pass_token,
            "time": headers.headers('getByModelAndDpid').timestamp
        },
        url: '/model_dp/getByModelAndDpid',
        method: 'GET',
        params: data
    })
}
// 根据code查询
export const codeQuery = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getByModelAndCode').pass_token,
            "time": headers.headers('getByModelAndCode').timestamp
        },
        url: '/model_dp/getByModelAndCode',
        method: 'GET',
        params: data
    })
}


// 新建，编辑，删除 物模型某页列表
export const saveModelList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/model_dp/save',
        method: 'POST',
        data: data
    })
}

// 获取设备型号的数据定义列表
export const getPageModelDefinList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/model_dp_item/getListByLimit',
        method: 'GET',
        params: data
    })
}

// 新增编辑 设备型号的数据定义列表
export const savePageModelDefinList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/model_dp_item/save',
        method: 'POST',
        data: data
    })
}


// 下发客户
export const sendToB = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('sendToB').pass_token,
            "time": headers.headers('sendToB').timestamp
        },
        url: '/model/sendToB',
        method: 'GET',
        params: data
    })
}


// 获取方案列表总数
export const getSchemeList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimitCount').pass_token,
            "time": headers.headers('getListByLimitCount').timestamp
        },
        url: '/model_plan/getListByLimitCount',
        method: 'GET',
        params: data
    })
}


// 获取方案某页列表
export const getPageSchemeList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/model_plan/getListByLimit',
        method: 'GET',
        params: data
    })
}


// 点击查看方案数据项
export const lookSchemeItem = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/model_plan_item/getListByLimit',
        method: 'GET',
        params: data
    })
}


// 新建，编辑，删除   方案列表
export const savePageSchemeList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/model_plan/save',
        method: 'POST',
        data: data
    })
}


// 新建，编辑，删除   方案数据列表
export const saveSchemeDataList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/model_plan_item/save',
        method: 'POST',
        data: data
    })
}


// 生成数据标识
export const createGeneratCode = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('generatCode').pass_token,
            "time": headers.headers('generatCode').timestamp
        },
        url: '/model_plan/generatCode',
        method: 'GET',
        params: data
    })
}

// 生成数据项数据标识
export const generatItemCode = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('generatItemCode').pass_token,
            "time": headers.headers('generatItemCode').timestamp
        },
        url: '/model_plan/generatItemCode',
        method: 'GET',
        params: data
    })
}

// 复制
export const copyModel = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('copyModel').pass_token,
            "time": headers.headers('copyModel').timestamp
        },
        url: '/model/copyModel',
        method: 'GET',
        params: data
    })
}


// 获取某型号下最大序列号
export const getMaxNo = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getMaxNo').pass_token,
            "time": headers.headers('getMaxNo').timestamp
        },
        url: '/model_dp/getMaxNo',
        method: 'GET',
        params: data
    })
}

