import {createApp} from 'vue'
import App from './App.vue'

import router from './router/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from './api/index'


createApp(App).use(router).use(ElementPlus).mount('#app')


// main.js
import * as ElIcon from '@element-plus/icons-vue'

const app = createApp(App)
for (let iconName in ElIcon) {
    app.component(iconName, ElIcon[iconName])
}

app.config.globalProperties.$axios = axios;


//登录拦截
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {//requireAuth若为true则该路由需要判断是否登录
        if (document.cookie.includes('userName')) {
            next()
        } else {
            next({//返回登录页面
                path: '/login',
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next();
    }
})