import md5 from './md5.js'

/*请求头的处理*/
export function headers(url) {
    let timestamp = Date.parse(new Date());
    let timestampString = String(timestamp);
    let index = timestampString.substring(timestampString.length - 1)
    let endNum = timestampString.substr(index, index + 1)
    let pass_token = md5('gW20230208' + url + timestamp + endNum)
    return {
        pass_token: pass_token,
        timestamp: timestamp
    }
}

export default {
    headers
}