import api from "@/api";
import headers from '../../assets/js/headers'

// 获取客户列表总数
export const getCustomerList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimitCount').pass_token,
            "time": headers.headers('getListByLimitCount').timestamp
        },
        url: '/b/getListByLimitCount',
        method: 'GET',
        params: data
    })
}

// 获取客户某页列表
export const getPageCustomerList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/b/getListByLimit',
        method: 'GET',
        params: data
    })
}


// 获取B端管理员列表
export const getBPageCustomerList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/b_manager/getListByLimit',
        method: 'GET',
        params: data
    })
}

// 新增,编辑 客户列表
export const saveCustomerList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/b/save',
        method: 'POST',
        data: data
    })
}

//删除 客户列表
export const delCustomerList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('delB').pass_token,
            "time": headers.headers('delB').timestamp
        },
        url: '/b/delB',
        method: 'GET',
        params: data
    })
}


// 新增,编辑，删除 B端管理员列表
export const saveBList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/b_manager/save',
        method: 'POST',
        data: data
    })
}


// 根据用户名查询
export const userQueryB = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getByUsername').pass_token,
            "time": headers.headers('getByUsername').timestamp
        },
        url: '/b_manager/getByUsername',
        method: 'GET',
        params: data
    })
}


// 导入网关
export const importGateway = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('importGateway').pass_token,
            "time": headers.headers('importGateway').timestamp
        },
        url: '/gateway/importGateway',
        method: 'POST',
        data: data
    })
}

// 上传
// 导入网关
export const uoloadFile = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('uploadFile').pass_token,
            "time": headers.headers('uploadFile').timestamp
        },
        url: '/upload/uploadFile',
        method: 'POST',
        data: data
    })
}


// 查看网关列表总数
export const getGatewayList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimitCount').pass_token,
            "time": headers.headers('getListByLimitCount').timestamp
        },
        url: '/gateway/getListByLimitCount',
        method: 'GET',
        params: data
    })
}

// 查看网关某页列表
export const getPageGatewayList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/gateway/getListByLimit',
        method: 'GET',
        params: data
    })
}


// 新增,编辑 客户列表
export const delGatewayList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/gateway/save',
        method: 'POST',
        data: data
    })
}


// 获取网关型号列表
export const getGatewayTypeList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/product/getListByLimit',
        method: 'GET',
        params: data
    })
}


// 根据设备标识查询
export const queryCode = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getByCode').pass_token,
            "time": headers.headers('getByCode').timestamp
        },
        url: '/gateway/getByCode',
        method: 'GET',
        params: data
    })
}
// 根据设备地址查询
export const queryAddr = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getByAddr').pass_token,
            "time": headers.headers('getByAddr').timestamp
        },
        url: '/gateway/getByAddr',
        method: 'GET',
        params: data
    })
}


// 新增网关
export const createGatewayFun = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/gateway/save',
        method: 'POST',
        data: data
    })
}