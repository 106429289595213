<template>
  <!--客户管理-->
  <div class="customer">
    <!--标题-->
    <div class="title">
      <p>客户管理</p>
      <div>
        <button>
          <a download="gateway.xlsx" href="https://lotb.380v.com/template/batch_import_gateway_a.xlsx">下载模版</a>
        </button>
        <button @click="createCustomer">新建客户</button>
      </div>
    </div>
    <!--客户表格-->
    <el-table v-loading="loading" :data="customerData"
              border
              style="width: 100%;margin-top: 10px"
              table-layout="auto">
      <el-table-column label="序号" prop="" width="100px">
        <template #default="scope">
          {{ (pageIndex - 1) * pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="客户名称" prop="name"/>
      <!--      <el-table-column label="创建时间" prop="create_time">-->
      <!--        <template #default="scope">{{ moment(scope.row.create_time).format('YYYY-MM-DD HH:mm:ss') }}</template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column label="创建人" prop="manager_id"/>-->
      <!--      <el-table-column label="备注" prop="remark"/>-->
      <el-table-column fixed="right" label="操作" width="250px">
        <template #default="scope">
          <div style="display: none;">
            <el-upload v-model:file-list="fileList" :before-upload="beforeUpload"
                       :data="personData" :show-file-list="false" accept=".xls,.xlsx"
                       action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" class="upload-demo">
              <button id="fileName" slot="trigger" class="selectFile" size="small">选择文件</button>
            </el-upload>
          </div>
          <ul>
            <li @click="editCustomer(scope)">编辑</li>
            <!--<li @click="createGateway(scope.row.id)">新建网关</li>-->
            <li @click="batchImport(scope)">导入网关</li>
            <li @click="lookGateway(scope)">查看网关</li>
            <li @click="delCustomer(scope)">删除</li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="pageSize" :total="total" background hide-on-single-page
                   layout="prev, pager, next" @current-change="changePage"
                   @next-click="changePage" @prev-click="changePage"/>
    <!--新增编辑客户弹窗-->
    <el-dialog
        v-model="customerAlert"
        :close-on-click-modal="false"
        :title="stateFlag?'新增客户':'编辑客户'"
        class="customerAlert"
        width="40%"
    >
      <el-form ref="customerFormRef" :model="customerForm" :rules="stateFlag?customerRulesCreate:customerRulesEdit"
               :validate="validate">
        <el-form-item label="客户名称" prop="name">
          <el-input v-model="customerForm.name" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="B端管理员用户名" prop="username">
          <el-input v-model="customerForm.username" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="customerForm.password" placeholder="单行输入" show-password type="password"/>
        </el-form-item>
        <el-form-item label="确认密码" prop="againPassword">
          <el-input v-model="customerForm.againPassword" placeholder="单行输入" show-password type="password"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="customerFormSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--新增网关弹窗-->
    <el-dialog
        v-model="createGatewayAlert"
        :close-on-click-modal="false"
        :title="新建网关"
        class="createGatewayAlert"
        width="40%"
    >
      <el-form :model="createGatewayFrom" :validate="validate">
        <el-form-item label="批次" prop="batch_no">
          <el-input v-model="createGatewayFrom.batch_no" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="网关PID" prop="product_id">
          <el-select v-model="createGatewayFrom.product_id" placeholder="请选择网关PID">
            <el-option v-for="(item,index) in gatewayTypeList" :key="index" :label="item.code"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="网关标识" prop="code">
          <el-input v-model="createGatewayFrom.code" placeholder="单行输入" @blur="codeBlur($event)"/>
        </el-form-item>
        <el-form-item label="通讯地址" prop="addr">
          <el-input v-model="createGatewayFrom.addr" placeholder="单行输入" @blur="addrBlur($event)"/>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="createGatewayFrom.remark" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="createGatewayFormSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--查看网关弹窗-->
    <el-dialog v-model="lookGatewayAlert" :close-on-click-modal="false" class="lookGatewayAlert" title="查看网关"
               width="85%">
      <div class="search">
        <el-input
            v-model="searchValue"
            :prefix-icon="Search"
            class="w-50 m-2" clearable
            placeholder="搜索网关标识"
        />
        <el-button @click="searchBth">搜索</el-button>
      </div>
      <el-table v-loading="lookLoading" :data="lookGatewayData" border style="width: 100%;margin-top: 20px"
                table-layout="auto">
        <el-table-column label="批次" prop="batch_no"/>
        <el-table-column label="网关PID" prop="product_code"/>
        <el-table-column label="网关标识" prop="code"/>
        <el-table-column label="网关PID别名" prop="product_name"/>
        <el-table-column label="通讯地址" prop="addr"/>
        <el-table-column label="软件版本号" prop="soft_v"/>
        <el-table-column label="硬件版本号" prop="hard_v"/>
        <el-table-column label="在线状态" prop="use_status">
          <template #default="scope">{{ scope.row.use_status == '1' ? '在线' : '离线' }}</template>
        </el-table-column>
        <el-table-column label="最后上报时间" prop="last_time">
          <template #default="scope">{{ moment(scope.row.last_time).format('YYYY-MM-DD HH:mm:ss') }}</template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" width="100px"/>
        <el-table-column label="操作" width="60px">
          <template #default="scope">
            <!--<p @click="lookGatewayUpgrade(scope)">查看网关升级记录</p>-->
            <p @click="delGateway(scope)">删除</p>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :page-size="gatewayPageSize" :total="gatewayTotal" background hide-on-single-page
                     layout="prev, pager, next" @current-change="gatewayChangePage"
                     @next-click="gatewayChangePage" @prev-click="gatewayChangePage"/>
    </el-dialog>
    <!--查看网关升级记录弹窗-->
    <!--    <el-dialog v-model="lookGatewayUpgradeAlert" :close-on-click-modal="false" class="lookGatewayUpgradeAlert"-->
    <!--               table-layout="auto" title="升级记录"-->
    <!--               width="80%">-->
    <!--      <el-table :data="lookGatewayUpgradeData" border style="width: 100%;margin-top: 20px">-->
    <!--        <el-table-column label="升级时间" prop="upgradeTime"/>-->
    <!--        <el-table-column label="软件版本" prop="softwareVersion"/>-->
    <!--        <el-table-column label="硬件版本" prop="hardwareVersion"/>-->
    <!--        <el-table-column label="升级用户" prop="upgradeUser"/>-->
    <!--      </el-table>-->
    <!--    </el-dialog>-->
    <!--删除客户弹窗-->
    <el-dialog v-model="delCustomerAlert" :close-on-click-modal="false" class="delCustomerAlert" width="40%">
      <h3>您确定要删除该客户吗？</h3>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="delCustomerAlert = false">取消</el-button>
        <el-button type="primary" @click="delCustomerFun">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import moment from 'moment'
// 引入样式
import './customer.less'
import router from "@/router";
import {Search} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from "element-plus";
import {v1 as uuidv1} from 'uuid'
import {
  createGatewayFun,
  delCustomerList, delGatewayList,
  getBPageCustomerList,
  getCustomerList, getGatewayList, getGatewayTypeList,
  getPageCustomerList, getPageGatewayList, importGateway, queryAddr, queryCode,
  saveBList,
  saveCustomerList, uoloadFile,
  userQueryB
} from '../../api/modules/customer'
import md5 from "@/assets/js/md5";
import {userObj} from "@/assets/js/userObj";

// 获取客户列表总数
const getCustomerListFun = function () {
  getCustomerList().then(res => {
    total.value = res.data
    getPageCustomerListFun(pageIndex.value)
  }).catch(err => {
    console.log(err);
  })
}
// 获取客户某页的列表
const getPageCustomerListFun = function (num) {
  loading.value = true
  getPageCustomerList({
    pageSize: pageSize.value,
    pageIndex: num
  }).then(res => {
    customerData.value = res.data
    loading.value = false
  }).catch(err => {
    console.log(err);
  })
}
// 当前展示页数
let pageIndex = ref(1)
// 每页数量
let pageSize = ref(20)
// 管理员总条数
let total = ref()
// A端登陆的客户id
let manager_id = ref()
onMounted(() => {
  getCustomerListFun()
  manager_id.value = Number(userObj())
})


let loading = ref(true)
// 客户列表--------------------------------------------------------
let customerData = ref([])


// 点击切换页数
const changePage = function (value) {
  pageIndex.value = value
  getPageCustomerListFun(value)
}


// 新建编辑客户的相关事件与数据******************************************************
let stateFlag = ref()
// 新建或编辑客户弹窗
let customerAlert = ref(false)
// 新建或编辑客户的弹窗表单
let customerForm = ref({
  name: '',
  username: '',
  password: '',
  againPassword: '',
})

// 新建客户
const createCustomer = function () {
  // 每次新建把表单清空
  customerForm.value = {
    name: '',
    username: '',
    password: '',
    againPassword: '',
  }
  customerAlert.value = true
  stateFlag.value = true
}
// 编辑客户
let editId = ref()
let b_manager_id = ref()
let editUsername = ref()
const editCustomer = function (scope) {
  // 获取本行信息
  editId.value = scope.row.id
  customerForm.value.name = scope.row.name
  customerForm.value.password = ''
  customerForm.value.againPassword = ''
  stateFlag.value = false
  customerAlert.value = true


  getBPageCustomerList({
    pageSize: -1,
    pageIndex: 1,
    status: 1,
    b_id: editId.value
  }).then(res => {
    customerForm.value.username = ''
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].lev === 1) {
        customerForm.value.username = res.data[i].username
        editUsername.value = res.data[i].username
        b_manager_id.value = res.data[i].id
      }
    }
  }).catch(err => {
    console.log(err);
  })
}

// 编辑新建客户的表单规则
// 再次输入密码的规则
let againPass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== customerForm.value.password) {
    callback(new Error('两次输入密码不一致!'))
  } else {
    callback()
  }
}
let username = async (rule, value, callback) => {
  let repeat = false
  await userQueryB({username: value}).then(res => {
    if (res.data.id) {
      repeat = true
    } else {
      repeat = false
    }
  }).catch(err => {
    console.log(err);
  })
  if (value === '') {
    callback(new Error('请输入B端管理员用户名'))
  } else if (repeat === true) {
    callback(new Error('B端管理员用户名重复!'))
    customerForm.value.username = ''
  } else {
    callback()
  }
}
let username1 = async (rule, value, callback) => {
  let repeat = false
  await userQueryB({username: value}).then(res => {
    if (res.data.id) {
      repeat = true
    } else {
      repeat = false
    }
  }).catch(err => {
    console.log(err);
  })
  if (value === '') {
    callback(new Error('请输入B端管理员用户名'))
  } else if (repeat === true && value !== editUsername.value) {
    callback(new Error('B端管理员用户名重复!'))
    customerForm.value.username = ''
  } else {
    callback()
  }
}
// 所有规则
const customerRulesCreate = reactive({
  name: [{required: true, message: "请输入客户名称", trigger: "blur"}],
  username: [{required: true, trigger: "blur", validator: username}],
  password: [{required: true, message: "请输入密码", trigger: "blur"}],
  againPassword: [{required: true, trigger: "blur", validator: againPass}]
})
const customerRulesEdit = reactive({
  name: [{required: true, message: "请输入客户名称", trigger: "blur"}],
  username: [{required: true, validator: username1, trigger: "blur"}],
  password: [{required: true, message: "请输入密码", trigger: "blur"}],
  againPassword: [{required: true, trigger: "blur", validator: againPass}]
})

// 表单元素
let customerFormRef = ref()
// 新建或编辑客户提交
const customerFormSubmit = function () {
  if (customerFormRef) {
    customerFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        let obj = {
          name: customerForm.value.name,
          create_time: Date.now(),
          status: 1,
          version: 1
        }
        // 判断是否是编辑
        if (!stateFlag.value) {
          obj.id = editId.value
        }
        // 先新增A端客户
        saveCustomerList(obj).then(res => {
          if (res.data.id) {
            // 再新增B端管理员
            let obj = {
              lev: 1,
              b_id: res.data.id,
              src: 1,
              manager_id: manager_id.value,
              username: customerForm.value.username,
              password: md5(customerForm.value.password + customerForm.value.username + 'gw'),
              create_time: Date.now(),
              status: 1,
              version: 1,
            }
            if (!stateFlag.value) {
              obj.id = b_manager_id.value
            }
            saveBList(obj).then(res => {
              getCustomerListFun()
              customerAlert.value = false
            }).catch(err => {
              console.log(err);
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}

// 新增网关******************************************************
// 新增网关弹窗
let createGatewayAlert = ref(false)
// 新增网关表单数据
let createGatewayFrom = ref({
  batch_no: '',
  product_id: '',
  code: '',
  addr: '',
  remark: '',
  b_id: '',
  type: 1,
  isbind: 0,
  project_id: 0,
  use_status: 0,
  iswait: 0,
  send_time: 0,
  manager_id: userObj(),
  status: 1,
  version: 1
})
// 网关型号列表
let gatewayTypeList = ref([])
// code失去焦点
const codeBlur = function (event) {
  queryCode({
    code: event.target.value
  }).then(res => {
    if (res.data.id) {
      ElMessage({
        message: '设备标识重复',
        type: 'info'
      })
      createGatewayFrom.value.code = ''
    }
  }).catch(err => {
    console.log(err);
  })
}
// addr失去焦点
const addrBlur = function (event) {
  queryAddr({
    addr: event.target.value
  }).then(res => {
    if (res.data.id) {
      ElMessage({
        message: '设备地址重复',
        type: 'info'
      })
      createGatewayFrom.value.addr = ''
    }
  }).catch(err => {
    console.log(err);
  })
}


// 点击新增网关
const createGateway = function (id) {
  createGatewayAlert.value = true
  createGatewayFrom.value = {
    batch_no: '',
    product_id: '',
    code: '',
    addr: '',
    remark: '',
    b_id: '',
    type: 1,
    isbind: 0,
    project_id: 0,
    use_status: 0,
    iswait: 0,
    send_time: 0,
    manager_id: userObj(),
    status: 1,
    version: 1
  }
  createGatewayFrom.value.b_id = id
  getGatewayTypeList({
    pageIndex: 1,
    pageSize: -1
  }).then(res => {
    gatewayTypeList.value = res.data
  }).catch(err => {
    console.log(err);
  })
}
// 确定新增网关(接口500）----------------------------------------------------------------------
const createGatewayFormSubmit = function () {
  console.log(createGatewayFrom.value)
  if (createGatewayFrom.value.batch_no !== '' && createGatewayFrom.value.product_id !== '' && createGatewayFrom.value.code !== '' && createGatewayFrom.value.addr !== '') {
    createGatewayFun(createGatewayFrom.value).then(res => {
      if (res.data.id) {
        console.log(res)
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    })
  } else {
    ElMessage({
      message: '缺少必填项',
      type: 'info'
    })
  }
}

// 导入网关的相关事件与数据******************************************************
// 导入网关
let personData = ref({}) //自定义上传参数
let fileList = ref([]) //上传文件
let b_id = ref()
const batchImport = function (scope) {
  b_id.value = scope.row.id
  document.getElementById("fileName").click();
}
// 上传
const beforeUpload = function (file) {
  let fd = new FormData();
  fd.append('file', file); //传文件
  fd.append('path', '/mnt/xvdb/html/gw/storage/upload/' + new Date().getFullYear() + new Date().getMonth() + 1); //传其他参数
  var name = uuidv1()
  var index = file.name.lastIndexOf(".");
  var type = file.name.substring(index, file.name.length);
  fd.append('fileName', name + type); //传其他参数
  uoloadFile(fd).then(res => {
    if (res.data) {
      importSubprojectFun(res.data);
    }
  }).catch(err => {
    console.log(err)
  })
}
// 导入
const importSubprojectFun = function (file) {
  let fd = new FormData();
  fd.append('file', file); //传文件
  fd.append('manager_id', manager_id.value); //传其他参数
  fd.append('b_id', b_id.value); //传其他参数
  importGateway(fd).then(res => {
    if (res.data.res) {
      ElMessage({
        message: '导入成功',
        type: 'success'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 查看网关的相关事件与数据******************************************************
// 网关表格数据
let lookGatewayData = ref([])
// 当前展示页数
let gatewayPageIndex = ref(1)
// 每页数量
let gatewayPageSize = ref(10)
// 管理员总条数
let gatewayTotal = ref()
// 获取网关列表总数
const getGatewayListFun = function (id) {
  getGatewayList({
    b_id: id,
  }).then(res => {
    gatewayTotal.value = res.data
    getPageGatewayListFun(gatewayPageIndex.value)
  }).catch(err => {
    console.log(err);
  })
}
// 获取网关某页的列表
let lookLoading = ref(true)
const getPageGatewayListFun = function (num, value) {
  lookLoading.value = true
  let obj = {
    pageSize: gatewayPageSize.value,
    pageIndex: num,
    b_id: bid.value,
    status: 1,
  }
  if (value) {
    obj.code = value
  }
  getPageGatewayList(obj).then(res => {
    lookGatewayData.value = res.data
    lookLoading.value = false
  }).catch(err => {
    console.log(err);
  })
}

// 点击切换页数
const gatewayChangePage = function (value) {
  gatewayPageIndex.value = value
  getPageGatewayListFun(value)
}

// 查看网关弹窗是否展示
let lookGatewayAlert = ref(false)
let bid = ref()
// 查看网关
const lookGateway = function (scope) {
  lookGatewayAlert.value = true
  getGatewayListFun(scope.row.id)
  bid.value = scope.row.id
}


// 搜索网关******************************************************
// 搜索的值
let searchValue = ref('')
// 搜索
const searchBth = function () {
  getPageGatewayListFun(1, searchValue.value)
}
document.onkeydown = function (e) { // 回车提交表单
// 兼容FF和IE和Opera
  var theEvent = window.event || e;
  var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
  if (code == 13) {
    searchBth();
  }
}


// 升级记录弹窗是否展示-------------------------------------------
// let lookGatewayUpgradeAlert = ref(false)
// // 查看网关升级记录
// const lookGatewayUpgrade = function (scope) {
//   lookGatewayUpgradeAlert.value = true
// }
// // 升级记录表格
// let lookGatewayUpgradeData = reactive([])


// 删除网关相关事件及数据
// 点击删除
const delGateway = function (scope) {
  ElMessageBox.confirm("确认删除该网关吗？", '删除网关', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(() => {
    delGatewayList({
      b_id: scope.row.b_id,
      id: scope.row.id,
      code: scope.row.code + '_' + scope.row.id,
      addr: scope.row.addr + '_' + scope.row.id,
      status: 0
    }).then(res => {
      if (res.data.id) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        getGatewayListFun(scope.row.b_id)
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}


// 删除客户的相关事件与数据******************************************************
// 删除的id
let delId = ref()
// 是否展示删除客户弹窗
let delCustomerAlert = ref(false)
// 删除客户
const delCustomer = function (scope) {
  delId.value = scope.row.id
  delCustomerAlert.value = true
}
// 确定删除客户
const delCustomerFun = function () {
  delCustomerList({
    id: delId.value,
    status: 0
  }).then(res => {
    if (res.data) {
      delCustomerAlert.value = false
      ElMessage({
        message: '删除成功',
        type: 'success',
      })
      getCustomerListFun()
    }
  }).catch(err => {
    console.log(err);
  })
}


</script>
