//导入axios
import axios from 'axios'

//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
const api = axios.create({
    timeout: 50000,   //请求超时设置，单位ms
    // baseURL: 'http://www.1942it.com:8089/gw' //请求后端数据的基本地址，自定义
    // baseURL: 'http://47.114.166.189:8089/gw' //请求后端数据的基本地址，自定义
    // baseURL: 'http://gatewayrestest.380v.com/gw'  //测试
    // baseURL: 'http://192.168.3.164:8088/'   //测试
    baseURL: 'https://gateway.rest.pro.380v.com'   //正式

})

//导出我们建立的axios实例模块，ES6 export用法
export default api

