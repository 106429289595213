import api from "@/api";
import headers from '../../assets/js/headers'

// 获取iot列表总数
export const getIotList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimitCount').pass_token,
            "time": headers.headers('getListByLimitCount').timestamp
        },
        url: '/channel/getListByLimitCount',
        method: 'GET',
        params: data
    })
}


// 获取iot某页列表
export const getPageIotList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/channel/getListByLimit',
        method: 'GET',
        params: data
    })
}
