<template>
  <div class="IOTPatform">
    <!--标题-->
    <div class="title">
      <p>IOT平台查看</p>
    </div>
    <!--IOT表格-->
    <el-table v-loading="loading" :data="IOTData" border style="width: 100%;margin-top: 10px" table-layout="auto">
      <el-table-column label="序号" prop="" width="100px">
        <template #default="scope">
          {{ (pageIndex - 1) * pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="IOT平台" prop="name"/>
    </el-table>
    <el-pagination :page-size="pageSize" :total="total" background hide-on-single-page
                   layout="prev, pager, next" @current-change="changePage"
                   @next-click="changePage" @prev-click="changePage"/>

  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import {getIotList, getPageIotList} from '../../api/modules/iotPatform'

// iot列表
let IOTData = ref([])
let loading = ref(true)

let pageIndex = ref(1)
let pageSize = ref(20)
let total = ref()

onMounted(() => {
  loading.value = true
  getIotList().then(res => {
    total.value = res.data
    getPageIotList({
      pageSize: pageSize.value,
      pageIndex: pageIndex.value
    }).then(res => {
      IOTData.value = res.data
      loading.value = false
    }).catch(err => {
      console.log(err);
    })
  }).catch(err => {
    console.log(err);
  })
})


// 点击切换页数
const changePage = function (value) {
  pageIndex.value = value
  loading.value = true
  getPageIotList({
    pageSize: pageSize.value,
    pageIndex: pageIndex.value
  }).then(res => {
    IOTData.value = res.data
    loading.value = false
  }).catch(err => {
    console.log(err);
  })
}
</script>

<style lang="less">
.IOTPatform {
  //标题
  .title {
    width: clac(100% - 20px);
    height: 40px;
    padding: 0 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    button {
      width: 120px;
      height: 30px;
      color: #ffffff;
      font-weight: 400;
      background-color: #2477F2;
      border: 0;
      border-radius: 5px;
    }
  }

  //网关表格
  .el-table {
    font-size: 14px;

    .el-table__header-wrapper {
      thead {
        tr {
          th {
            color: #000000 !important;
            font-weight: 400;
          }
        }
      }
    }

    .el-table__body-wrapper {
      tbody {
        .el-table__row {
          height: 40px !important;

          .el-table__cell {
            height: 40px !important;
            padding: 0;
          }
        }

      }

      ul {
        display: flex;
        justify-content: space-around;
        padding: 0;
        margin: 0;

        li {
          color: #2477F2;
          cursor: pointer;
        }
      }
    }

    .el-table__cell {
      padding: 12px 0;
    }
  }
}

</style>
