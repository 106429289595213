<!--  -->
<template>
  <div class=''>
    <TransferExtend
        ref="wl-tree-transfer"
        :defaultCheckedKeys="defaultCheckedKeys"
        :defaultExpandedKeys="[2, 3]"
        :defaultProps="defaultProps"
        :filterNode="filterNode"
        :from_data="fromData"
        :title="['发布范围','不发布范围']"
        :to_data="toData"
        check-strictly
        draggable
        filter
        high-light
        mode="mode"
        node_key="id"
        @right-check-change="rightCheckChange"
        @left-check-change="leftCheckChange"
        @remove-btn="remove"
        @add-btn="add"
    >
    </TransferExtend>
  </div>
</template>

<script setup>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import TransferExtend from './transfer-extend'

// 穿梭框数据及事件--------------------------------
let mode = "transfer" // transfer addressList
let defaultProps = {
  label: "name",
  children: "children",
  /* disabled(data) {
    return data.id === 2;
  }, */
}
let fromData = [
  {
    id: 1,
    pid: 0,
    name: "测试左侧",
    children: [
      {
        id: 2,
        pid: 1,
        name: "水电费是打发斯蒂芬斯蒂芬gas噶水电费噶地方死光光",
        children: [],
      },
      {
        id: 3,
        pid: 1,
        name: "11-3",
        children: [
          {
            id: "11-3-1",
            pid: 3,
            name: "11-3-1",
            children: [
              {
                id: "11-3-1-1",
                name: "11-3-1-1",
                pid: "11-3-1",
              },
            ],
          },
        ],
      },
      {
        id: 4,
        pid: 1,
        name: "11-4",
        children: [
          {
            id: 5,
            pid: 4,
            name: "11-5",
            children: [
              {
                id: 111,
                pid: 5,
                name: "11-111",
              },
            ],
          },
          {
            id: 6,
            pid: 4,
            name: "11-6",
            children: [
              {
                id: "6-1",
                pid: 6,
                name: "6-1",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 7127,
    pid: 0,
    name: "debug",
    children: [
      {
        id: 71272,
        pid: 7127,
        name: "debug22",
        // disabled: true,
        children: [],
      },
      {
        id: 71273,
        pid: 7127,
        name: "debug11",
        children: [],
      },
      {
        id: 71274,
        pid: 7127,
        name: "debug33",
        children: [],
      },
    ],
  },
]// 穿梭框 - 源数据 - 树形
let toData = [
  {
    pid: 0,
    id: 3333,
    name: "测试右侧",
    children: [{pid: 3333, id: "3333-1", name: "3333-1"}],
  },
]// 穿梭框 - 目标数据 - 树形
let fromArray = [
  {
    id: "1",
    name: "1",
    pid: "0",
  },
  {
    id: "2",
    name: "2",
    pid: "0",
  },
  {
    id: "1-1",
    name: "1-1",
    pid: "1",
  },
  {
    id: "1-2",
    name: "1-2",
    pid: "1",
  },
  {
    id: "1-1-1",
    name: "1-1-1",
    pid: "1-1",
  },
  {
    id: "1-1-1-1",
    name: "1-1-1-1",
    pid: "1-1-1",
  },
  {
    id: "2-1",
    name: "2-1",
    pid: "2",
  },
  {
    id: "2-2",
    name: "2-2",
    pid: "2",
  },
]
let toArray = []
let defaultCheckedKeys = [] // 左侧默认选中数据


// 事件
// 获取选中
const getChecked = function () {
  const checkeds = this.$refs["wl-tree-transfer"].getChecked();
  console.log("获取选中：", checkeds);
}
// 设置选中
const setChecked = function () {
  this.$refs["wl-tree-transfer"].setChecked([3], [2]);
}
// 清除选中
const clearChecked = function () {
  this.$refs["wl-tree-transfer"].clearChecked();
}
// 自定义筛选函数
const filterNode = function (value, data, where) {
  console.log(value, data, where);
  if (!value) return true;
  return data[this.defaultProps.label].indexOf(value) !== -1;
}
// 懒加载回调
const lazyFn = function (node, resolve) {
  setTimeout(() => {
    resolve([
      {
        id: 71272,
        pid: 7127,
        name: "debug22",
        // disabled: true,
        children: [],
      },
      {
        id: 71273,
        pid: 7127,
        name: "debug11",
        children: [],
      },
    ]);
  }, 500);
}
// 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
const changeMode = function () {
  if (this.mode == "transfer") {
    this.mode = "addressList";
  } else {
    this.mode = "transfer";
  }
}
// 添加按钮
const add = function (fromData, toData, obj) {
  // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
  // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
  console.log("fromData:", fromData);
  console.log("toData:", toData);
  console.log("obj:", obj);
}
// 移除按钮
const remove = function (fromData, toData, obj) {
  // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
  // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
  console.log("fromData:", fromData);
  console.log("toData:", toData);
  console.log("obj:", obj);
}
// 左侧源数据选中事件
const leftCheckChange = function (nodeObj, treeObj, checkAll) {
  console.log(nodeObj);
  console.log(treeObj);
  console.log(checkAll);
}
// 右侧目标数据选中事件
const rightCheckChange = function (nodeObj, treeObj, checkAll) {
  console.log(nodeObj);
  console.log(treeObj);
  console.log(checkAll);
}
// 自定义节点 仅树形结构支持
const renderContent = function (h, {node, data, store}) {
  return (
      <span class="custom-tree-node">
          <span>{node.label}</span>
          <span>
            <el-button size="mini" type="text" on-click={() => this.append(data)}>
              Append
            </el-button>
            <el-button size="mini" type="text" on-click={() => this.remove(node, data)}>
              Delete
            </el-button>
          </span>
        </span>
  );
}
// 标题自定义区点击事件
const handleTitleRight = function () {
  alert("标题自定义区点击事件");
}

</script>