<template>
  <!--网关型号管理-->
  <div class="gatewayType">
    <!--标题-->
    <div class="title">
      <p>网关PID管理</p>
      <button @click="createGatewayType">新建网关型号</button>
    </div>
    <!--网关表格-->
    <el-table v-loading="loading" :data="gatewayData" border style="width: 100%;margin-top: 10px" table-layout="auto">
      <el-table-column label="序号" prop="" width="100px">
        <template #default="scope">
          {{ (pageIndex - 1) * pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="网关PID" prop="code"/>
      <el-table-column label="网关PID别名" prop="name"/>
      <el-table-column label="发布topic名称" prop="down_topic_name"/>
      <el-table-column label="订阅topic名称" prop="up_topic_name"/>
      <el-table-column label="IOT平台" prop="channel_name"/>
      <el-table-column label="是否物模型网关" prop="has_model">
        <template #default="scope">{{ scope.row.has_model ? '是' : '否' }}</template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150px">
        <template #default="scope">
          <ul>
            <li @click="editGatewayType(scope)">编辑</li>
            <li @click="delGatewayType(scope)">删除</li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="pageSize" :total="total" background hide-on-single-page
                   layout="prev, pager, next" @current-change="changePage"
                   @next-click="changePage" @prev-click="changePage"/>
    <!--新增编辑网关弹窗-->
    <el-dialog
        v-model="gatewayAlert"
        :close-on-click-modal="false"
        :title="stateFlag?'新增网关型号':'编辑网关型号'"
        class="gatewayAlert"
        width="40%"
    >
      <el-form ref="gatewayFormRef" :model="gatewayForm" :rules="stateFlag?gatewayRulesCreate:gatewayRulesEdit"
               :validate="validate">
        <el-form-item label="网关PID" prop="code">
          <el-input v-model="gatewayForm.code" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="网关PID别名" prop="name">
          <el-input v-model="gatewayForm.name" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="发布topic名称" prop="down_topic_name">
          <el-select v-model="gatewayForm.up_topic_name" placeholder="请选择发布topic名称">
            <el-option label="user/dnData" value="user/dnData"></el-option>
            <el-option label="user/para_dnData" value="user/para_dnData"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订阅topic名称" prop="up_topic_name">
          <el-select v-model="gatewayForm.down_topic_name" placeholder="请选择订阅topic名称">
            <el-option label="user/upData" value="user/upData"></el-option>
            <el-option label="user/para_upData" value="user/para_upData"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="IOT平台" prop="channel_id">
          <el-select v-model="gatewayForm.channel_id" placeholder="请选择IOT平台">
            <el-option
                v-for="item in IOTPlatform"
                :key="item.name"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否是物模型" prop="has_model" style="display: flex">
          <el-radio-group v-model="gatewayForm.has_model">
            <el-radio label="是"/>
            <el-radio label="否"/>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="gatewayFormSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--删除网关弹窗-->
    <el-dialog v-model="delGatewayTypeAlert" :close-on-click-modal="false" class="delGatewayTypeAlert" width="40%">
      <h3>您确定要删除该网关型号吗？</h3>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="delGatewayTypeAlert = false">取消</el-button>
        <el-button type="primary" @click="delGatewayTypeFun">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
// 引入样式
import './gatewayType.less'
import {ElMessage} from "element-plus";
import {getGatewayTypeList, getPageGatewayTypeList, gatewayQuery, saveGatewayTypeList} from "@/api/modules/gatewayType";
import {getPageIotList} from "@/api/modules/iotPatform";
import {userObj} from "@/assets/js/userObj";
import {userQueryB} from "@/api/modules/customer";
// 网关总条数
const getGatewayTypeListFun = function () {
  getGatewayTypeList().then(res => {
    total.value = res.data
    getPageGatewayTypeListFun(pageIndex.value)
  }).catch(err => {
    console.log(err);
  })
}
// 每页列表
const getPageGatewayTypeListFun = function (num) {
  loading.value = true
  getPageGatewayTypeList({
    pageSize: pageSize.value,
    pageIndex: num
  }).then(res => {
    gatewayData.value = res.data
    loading.value = false
  }).catch(err => {
    console.log(err);
  })
}

// 当前展示页数
let pageIndex = ref(1)
// 每页数量
let pageSize = ref(20)
// 管理员总条数
let total = ref()
onMounted(async () => {
  getGatewayTypeListFun()
  // 获取iot平台列表
  getPageIotList({
    pageSize: -1,
    pageIndex: 1
  }).then(res => {
    IOTPlatform.value = res.data
  }).catch(err => {
    console.log(err);
  })
})

let loading = ref(true)
// 网关表格--------------------------------------------------------
let gatewayData = ref([])

// 点击切换页数
const changePage = function (value) {
  pageIndex.value = value
  getPageGatewayTypeListFun(value)
}


// 新建编辑网关型号的相关事件与数据******************************************************
// IOT平台列表
let IOTPlatform = ref([])
// 新建或编辑网关弹窗
let gatewayAlert = ref(false)
// 新建或编辑网关的弹窗表单
let gatewayForm = ref({
  code: '',
  name: '',
  up_topic_name: '',
  down_topic_name: '',
  channel_id: '',
  has_model: '',
  create_time: Date.now(),
  manager_id: Number(userObj()),
  status: 1,
  version: 1,
})


// 判断是新建还是编辑
let stateFlag = ref()
// 新建网关
const createGatewayType = function () {
  // 每次新建把表单清空
  gatewayForm.value = {
    code: '',
    name: '',
    up_topic_name: '',
    down_topic_name: '',
    channel_id: '',
    has_model: '',
    create_time: Date.now(),
    manager_id: Number(userObj()),
    status: 1,
    version: 1,
  }
  stateFlag.value = true
  gatewayAlert.value = true
}
// 编辑的网关id
let editId = ref()
let editCode = ref()
// 编辑网关
const editGatewayType = function (scope) {
  // 获取本行信息
  gatewayForm.value.code = scope.row.code
  gatewayForm.value.name = scope.row.name
  gatewayForm.value.up_topic_name = scope.row.up_topic_name
  gatewayForm.value.down_topic_name = scope.row.down_topic_name
  gatewayForm.value.channel_id = scope.row.channel_id
  gatewayForm.value.has_model = scope.row.has_model
  if (scope.row.has_model == 1) {
    gatewayForm.value.has_model = '是'
  } else {
    gatewayForm.value.has_model = '否'
  }
  editId.value = scope.row.id
  editCode.value = scope.row.code
  stateFlag.value = false
  gatewayAlert.value = true
}

// 网关PID规则
let pid1 = async (rule, value, callback) => {
  let repeat = false
  await gatewayQuery({code: value}).then(res => {
    if (res.data.id) {
      repeat = true
    } else {
      repeat = false
    }
  }).catch(err => {
    console.log(err);
  })
  if (value === '') {
    callback(new Error('请输入网关PID'))
  } else if (repeat === true) {
    callback(new Error('网关PID重复!'))
    gatewayForm.value.code = ''
  } else {
    callback()
  }
}
let pid2 = async (rule, value, callback) => {
  let repeat = false
  await gatewayQuery({code: value}).then(res => {
    if (res.data.id) {
      repeat = true
    } else {
      repeat = false
    }
  }).catch(err => {
    console.log(err);
  })
  if (value === '') {
    callback(new Error('请输入网关PID'))
  } else if (repeat === true && editCode.value !== value) {
    callback(new Error('网关PID重复!'))
    gatewayForm.value.code = ''
  } else {
    callback()
  }
}
// 编辑新建网关的表单规则
const gatewayRulesCreate = reactive({
  code: [{required: true, trigger: "blur", validator: pid1}],
  name: [{required: true, message: "请输入网关PID别名", trigger: "blur"}],
  channel_id: [{required: true, message: "请选择IOT平台", trigger: "blur"}],
  has_model: [{required: true, message: "请选择是否是物模型", trigger: "blur"}]
})
const gatewayRulesEdit = reactive({
  code: [{required: true, trigger: "blur", validator: pid2}],
  name: [{required: true, message: "请输入网关PID别名", trigger: "blur"}],
  up_topic_name: [{required: true, message: "请输入上行topic名称", trigger: "blur"}],
  down_topic_name: [{required: true, message: "请输入下行topic名称", trigger: "blur"}],
  channel_id: [{required: true, message: "请选择IOT平台", trigger: "blur"}],
  has_model: [{required: true, message: "请选择是否是物模型", trigger: "blur"}]
})

// 表单元素
let gatewayFormRef = ref()
// 新建或编辑网关提交
const gatewayFormSubmit = function () {
  if (gatewayFormRef) {
    gatewayFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        let obj = gatewayForm.value
        if (obj.has_model === '是') {
          obj.has_model = 1
        } else {
          obj.has_model = 0
        }
        obj.code = obj.code.replace(/\s*/g, "")
        obj.name = obj.name.replace(/\s*/g, "")

        if (!stateFlag.value) {
          obj.id = editId.value
        }
        saveGatewayTypeList(obj).then(res => {
          if (res.data.id) {
            getGatewayTypeListFun()
            gatewayAlert.value = false
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 删除网关的相关事件与数据******************************************************
// 是否展示删除网关弹窗
let delGatewayTypeAlert = ref(false)
// 编辑的网关id
let delId = ref()
let delCode = ref()
// 删除网关
const delGatewayType = function (scope) {
  delId.value = scope.row.id
  delCode.value = scope.row.code + '_' + scope.row.id
  delGatewayTypeAlert.value = true
}
// 确定删除网关
const delGatewayTypeFun = function () {
  saveGatewayTypeList({
    id: delId.value,
    code: delCode.value,
    status: 0
  }).then(res => {
    if (res.data.id) {
      getGatewayTypeListFun()
      delGatewayTypeAlert.value = false
      ElMessage({
        message: '删除成功',
        type: 'success',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


</script>