<template>
  <div class="administrator">
    <!--标题-->
    <div class="title">
      <p>管理员管理</p>
      <button @click="createAdministrator">新建管理员</button>
    </div>
    <!--管理员表格-->
    <el-table v-loading="loading" :data="administratorData" border style="width: 100%;margin-top: 10px"
              table-layout="auto">
      <el-table-column label="序号" prop="" width="100px">
        <template #default="scope">
          {{ (pageIndex - 1) * pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="管理员用户名" prop="username"/>
      <el-table-column fixed="right" label="操作" width="150px">
        <template #default="scope">
          <ul>
            <li @click="editAdministrator(scope)">编辑</li>
            <li @click="delAdministrator(scope)">删除</li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="pageSize" :total="total" background hide-on-single-page
                   layout="prev, pager, next" @current-change="changePage"
                   @next-click="changePage" @prev-click="changePage"/>

    <!--新增编辑管理员弹窗-->
    <el-dialog
        v-model="administratorAlert"
        :close-on-click-modal="false"
        :title="stateFlag?'新增管理员':'编辑管理员'"
        class="administratorAlert"
        width="40%"
    >
      <el-form ref="administratorFormRef" :model="administratorForm"
               :rules="stateFlag?administratorRulesCreate:administratorRulesEdit" :validate="validate">
        <el-form-item label="管理员用户名" prop="username">
          <el-input v-model="administratorForm.username" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="administratorForm.password" placeholder="单行输入" show-password type="password"/>
        </el-form-item>
        <el-form-item label="确认密码" prop="againPassword">
          <el-input v-model="administratorForm.againPassword" placeholder="单行输入" type="password"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="administratorFormSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--删除管理员弹窗-->
    <el-dialog v-model="delAdministratorAlert" :close-on-click-modal="false" class="delGatewayTypeAlert" width="40%">
      <h3>您确定要删除该管理员账号吗？</h3>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="delAdministratorAlert = false">取消</el-button>
        <el-button type="primary" @click="delAdministratorFun">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './administrator.less'
import {ElMessage} from "element-plus";

import {getAdministratorList, getPageList, saveAdministratorList, userQuery} from '../../api/modules/administrator'
import qs from "qs";
import md5 from '../../assets/js/md5'


// 获取管理员列表******************************************************
const getAdministratorListFun = function () {
  getAdministratorList().then(res => {
    total.value = res.data
    getPageListFun(pageIndex.value)
  }).catch(err => {
    console.log(err);
  })
}

// 获取管理员某页列表
const getPageListFun = function (num) {
  loading.value = true
  getPageList({
    pageSize: pageSize.value,
    pageIndex: num
  }).then(res => {
    administratorData.value = res.data
    loading.value = false
  }).catch(err => {
    console.log(err);
  })
}

let loading = ref(true)
onMounted(() => {
  getAdministratorListFun()
})


// 当前展示页数
let pageIndex = ref(1)
// 每页数量
let pageSize = ref(20)
// 管理员总条数
let total = ref()
// 管理员表格--------------------------------------------------------
let administratorData = ref([])

// 点击切换页数
const changePage = function (value) {
  pageIndex.value = value
  getPageListFun(value)
}


// 新建编辑管理员的相关事件与数据******************************************************
// 新建或编辑状态
let stateFlag = ref(true)
// 新建或编辑管理员弹窗
let administratorAlert = ref(false)
// 新建或编辑管理员的弹窗表单
let administratorForm = ref({
  lev: 1,
  username: '',
  password: '',
  againPassword: '',
  status: 1,
  create_time: Date.now()
})

// 新建管理员
const createAdministrator = function () {
  // 每次新建把表单清空
  administratorForm.value = {
    lev: 1,
    username: '',
    password: '',
    againPassword: '',
    status: 1,
    create_time: Date.now()
  }
  stateFlag.value = true
  administratorAlert.value = true
}
// 编辑管理员
let editId = ref()
let editUsername = ref()
const editAdministrator = function (scope) {
  // 获取本行信息
  administratorForm.value.username = scope.row.username
  administratorForm.value.password = ''
  administratorForm.value.againPassword = ''

  editUsername.value = scope.row.username
  editId.value = scope.row.id
  stateFlag.value = false
  administratorAlert.value = true
}

// 编辑新建管理员的表单规则
let againPass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== administratorForm.value.password) {
    callback(new Error('两次输入密码不一致!'))
  } else {
    callback()
  }
}
let username = async (rule, value, callback) => {
  let repeat = false
  await userQuery({username: value}).then(res => {
    if (res.data) {
      repeat = true
    } else {
      repeat = false
    }
  }).catch(err => {
    console.log(err);
  })
  if (value === '') {
    callback(new Error('请输入管理员用户名'))
  } else if (repeat === true) {
    callback(new Error('管理员用户名重复!'))
    administratorForm.value.username = ''
  } else {
    callback()
  }
}
let username1 = async (rule, value, callback) => {
  let repeat = false
  await userQuery({username: value}).then(res => {
    if (res.data) {
      repeat = true
    } else {
      repeat = false
    }
  }).catch(err => {
    console.log(err);
  })
  if (value === '') {
    callback(new Error('请输入管理员用户名'))
  } else if (repeat === true && value !== editUsername.value) {
    callback(new Error('管理员用户名重复!'))
    administratorForm.value.username = ''
  } else {
    callback()
  }
}
const administratorRulesCreate = reactive({
  username: [{required: true, trigger: "blur", validator: username}],
  password: [{required: true, message: "请输入管理员密码", trigger: "blur"}],
  againPassword: [{required: true, trigger: "blur", validator: againPass}]
})
const administratorRulesEdit = reactive({
  username: [{required: true, validator: username1, trigger: "blur"}],
  password: [{required: true, message: "请输入管理员密码", trigger: "blur"}],
  againPassword: [{required: true, trigger: "blur", validator: againPass}]
})

// 表单元素
let administratorFormRef = ref()
// 新建或编辑管理员提交
const administratorFormSubmit = function () {
  if (administratorFormRef) {
    administratorFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        let obj = administratorForm.value
        obj.password = md5(obj.password + obj.username + 'gw')
        delete obj.againPassword
        // 判断如果是编辑添加id
        if (!stateFlag.value) {
          obj.id = editId.value
        }
        saveAdministratorList(obj).then(res => {
          administratorAlert.value = false
          getAdministratorListFun()
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 删除管理员的相关事件与数据******************************************************
// 是否展示删除管理员弹窗
let delAdministratorAlert = ref(false)
// 删除管理员
let delId = ref()
let delUsername = ref()
const delAdministrator = function (scope) {
  delId.value = scope.row.id
  delUsername.value = scope.row.username
  delAdministratorAlert.value = true
}
// 确定删除管理员
const delAdministratorFun = function () {
  saveAdministratorList({
    id: delId.value,
    username: delUsername.value + '_' + delId.value,
    status: 0
  }).then(res => {
    if (res.data.id) {
      delAdministratorAlert.value = false
      ElMessage({
        message: '删除成功',
        type: 'success',
      })
      getAdministratorListFun()
    }
  }).catch(err => {
    console.log(err);
  })
}
</script>
