<template>
  <div class="upgradeFile">
    <!--选择框-->
    <div class="select">
      <el-select v-model="gatewayValue" placeholder="网关pdid+别名">
        <el-option
            v-for="item in idList"
            :key="item"
            :label="item"
            :value="item"
        />
      </el-select>
    </div>
    <!--标题-->
    <div class="title">
      <p>升级文件管理</p>
      <button @click="createUpgradeFile">新建升级文件</button>
    </div>
    <!--升级文件表格-->
    <el-table :data="upgradeFileData" border style="width: 100%;margin-top: 10px" table-layout="auto">
      <el-table-column label="序号" prop="idx"/>
      <el-table-column label="版本号" prop="versionNumber"/>
      <el-table-column label="文件名" prop="fileName"/>
      <el-table-column label="升级说明" prop="upgradeDescription"/>
      <el-table-column label="验证状态" prop="validationState"/>
      <el-table-column label="发布状态" prop="releaseStatus"/>
      <el-table-column fixed="right" label="操作" width="300px">
        <template #default="scope">
          <ul>
            <li @click="editUpgradeFile(scope)">编辑</li>
            <li @click="verificationFun(scope)">验证</li>
            <li @click="upgradeFun(scope)">升级管理</li>
            <li @click="recordFun(scope)">升级记录</li>
            <li @click="lookFun(scope)">查看</li>
            <li @click="delUpgradeFile(scope)">删除</li>
          </ul>
        </template>
      </el-table-column>
    </el-table>

    <!--新增编辑升级文件弹窗-->
    <el-dialog
        v-model="upgradeFileAlert"
        :close-on-click-modal="false"
        class="upgradeFileAlert"
        title="新建升级文件"
        width="70%"
    >
      <div class="form">
        <div class="formBox">
          <p>验证参数</p>
          <el-form :model="upgradeFileForm">
            <el-form-item label="密码" prop="password">
              <el-input v-model="upgradeFileForm.password" placeholder="单行输入"/>
            </el-form-item>
            <el-form-item label="操作者代码" prop="code">
              <el-input v-model="upgradeFileForm.code" placeholder="单行输入"/>
            </el-form-item>
            <el-form-item label="报文时间间隔" prop="timeInterval">
              <el-input v-model="upgradeFileForm.timeInterval" placeholder="单行输入"/>
            </el-form-item>
            <el-form-item label="重复次数" prop="frequency">
              <el-input v-model="upgradeFileForm.frequency" placeholder="单行输入"/>
            </el-form-item>
            <el-form-item label="最大报文字节数" prop="maximum">
              <el-input v-model="upgradeFileForm.maximum" placeholder="单行输入"/>
            </el-form-item>
          </el-form>
        </div>
        <div class="formBox">
          <p>版本信息</p>
          <el-form :model="upgradeFileForm">
            <el-form-item label="版本号" prop="versionNumber">
              <el-input v-model="upgradeFileForm.versionNumber" placeholder="单行输入"/>
            </el-form-item>
            <el-form-item label="升级说明" prop="upgradeDescription">
              <el-input v-model="upgradeFileForm.upgradeDescription" placeholder="单行输入"/>
            </el-form-item>
            <el-form-item label="更新记录" prop="updateRecord">
              <el-input v-model="upgradeFileForm.updateRecord" placeholder="单行输入"/>
            </el-form-item>
            <el-form-item label="硬件范围" prop="hardwareRange">
              <el-input v-model="upgradeFileForm.hardwareRange" placeholder="单行输入"/>
            </el-form-item>
            <el-form-item label="软件范围" prop="softwareRange">
              <el-input v-model="upgradeFileForm.softwareRange" placeholder="单行输入"/>
            </el-form-item>
            <el-form-item label="上传升级文件" prop="upload">
              <input :model="upgradeFileForm.upload" type="file"/>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-button type="primary" @click="upgradeFileFormSubmit">确定</el-button>
    </el-dialog>

    <!--删除网关弹窗-->
    <el-dialog v-model="delUpgradeFileAlert" :close-on-click-modal="false" class="delGatewayTypeAlert" width="40%">
      <h3>您确定要删除该升级文件吗？</h3>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="delUpgradeFileAlert = false">取消</el-button>
        <el-button type="primary" @click="delUpgradeFileFun">确定</el-button>
      </span>
      </template>
    </el-dialog>

    <!--验证网关-->
    <el-dialog v-model="verificationAlert" :close-on-click-modal="false" class="verificationAlert" title="验证网关"
               width="40%">
      <el-form :model="verificationForm">
        <el-form-item label="网关PID" prop="gatewayPID">
          <el-select v-model="verificationForm.gatewayPID" placeholder="pid+别名">
            <el-option
                v-for="item in idList"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="通讯地址" prop="address">
          <el-input v-model="verificationForm.address" placeholder="单行输入"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="verificationFunClick">验证</el-button>
      </template>
    </el-dialog>

    <!--升级管理-->
    <el-dialog v-model="upgradeManagementAlert" :close-on-click-modal="false" class="upgradeManagementAlert"
               title="升级管理" width="40%">
      <!--版本，验证状态信息-->
      <div class="info">
        <p>版本号：2.1.1</p>
        <p>验证状态：未验证</p>
      </div>
      <p class="tit">发布范围</p>
      <!--穿梭框-->
      <TreeTransfer/>
      <!--取消升级-->
      <div class="operation">
        <el-button @click="upgradeManagementAlert=false">取消</el-button>
        <el-button @click="confirmUpgradeAlert=true">升级</el-button>
      </div>
    </el-dialog>

    <!--确认升级弹窗-->
    <el-dialog v-model="confirmUpgradeAlert" :close-on-click-modal="false" class="confirmUpgradeAlert" width="40%">
      <!--确认发布提示-->
      <div class="confirmBody">
        <img alt="" src="../../assets/images/jinggao.svg">
        <p>确认要发布吗？</p>
      </div>
      <!--取消确认-->
      <div class="operation">
        <el-button @click="confirmUpgradeAlert=false">取消</el-button>
        <el-button @click="confirmUpgradeSubmit">确认</el-button>
      </div>
    </el-dialog>

    <!--已完成升级弹窗-->
    <el-dialog v-model="completeUpgradeAlert" :close-on-click-modal="false" class="completeUpgradeAlert" title="升级管理"
               width="40%">
      <!--版本，验证状态信息-->
      <div class="info">
        <p>版本号：2.1.1</p>
        <p>验证状态：未验证</p>
      </div>
      <!--表格-->
      <el-table :data="upgradeResultData" border style="width: 100%" table-layout="auto">
        <el-table-column label="PID+别名" prop="PIDName"/>
        <el-table-column label="升级结果" prop="result"/>
      </el-table>
      <!--取消升级-->
      <div class="operation">
        <el-button @click="cancelUpgrade">取消升级</el-button>
      </div>
    </el-dialog>


    <!--升级记录-->
    <el-dialog v-model="recordAlert" :close-on-click-modal="false" class="recordAlert" table-layout="auto" title="升级记录"
               width="80%">
      <el-table :data="recordData" border style="width: 100%;margin-top: 10px">
        <el-table-column label="序号" prop="idx"/>
        <el-table-column label="升级时间" prop="upgradeTime"/>
        <el-table-column label="网关ID" prop="gatewayId"/>
        <el-table-column label="网关地址" prop="gatewayAddress"/>
        <el-table-column label="升级前软件版本" prop="upgradeSoft"/>
        <el-table-column label="升级前硬件版本" prop="upgradeHard"/>
        <el-table-column label="升级用户" prop="upgradeUser"/>
        <el-table-column fixed="right" label="操作" width="80px">
          <template #default="scope">
            <p @click="lookRecord(scope)">查看</p>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!--升级详情-->
    <el-dialog v-model="recordDetailAlert" :close-on-click-modal="false" class="recordDetailAlert" title="新增网关"
               width="40%">
      <div class="list">
        <ul>
          <li>升级类型</li>
          <li>产品ID</li>
          <li>网关阿里注册名</li>
          <li>密码</li>
          <li>操作者代码</li>
          <li>报文时间间隔(毫秒)</li>
          <li>网关地址</li>
          <li>重复次数</li>
          <li>报文最大字节数</li>
          <li>升级时间</li>
          <li>操作人</li>
          <li>升级文件名称</li>
          <li>升级前版本</li>
        </ul>
        <ul>
          <li>表</li>
          <li>43frvregt54</li>
          <li>vrgegv5t4gvrgvb45</li>
          <li>454fre43</li>
          <li>vregvr</li>
          <li>102</li>
          <li>vregvgfbtgbfdgbfdb</li>
          <li>2</li>
          <li>1000</li>
          <li>2020-09-20 09:00:00</li>
          <li>张三</li>
          <li>zzk1.02</li>
          <li>2.0.0</li>
        </ul>
      </div>
      <el-button @click="recordDetailAlert=false">确定</el-button>
    </el-dialog>

    <!--查看-->
    <el-dialog v-model="lookAlert" :close-on-click-modal="false" class="lookAlert" table-layout="auto" title="查看升级情况"
               width="80%">
      <el-table :data="lookData[0]" border style="width: 100%;">
        <el-table-column label="密码" prop="password"/>
        <el-table-column label="操作者代码" prop="codePeople"/>
        <el-table-column label="报文时间间隔" prop="timeInterval"/>
        <el-table-column label="重复次数" prop="frequency"/>
        <el-table-column label="最大报文字节数" prop="byteNumber"/>
        <el-table-column label="密码" prop="pass"/>
        <el-table-column label="操作代码" prop="code"/>
        <el-table-column label="版本号" prop="versionNumber"/>
      </el-table>

      <el-table :data="lookData[1]" border style="width: 100%;margin-top: 20px" table-layout="auto">
        <el-table-column label="软件版本范围" prop="soft"/>
        <el-table-column label="硬件版本范围" prop="hard"/>
      </el-table>

      <el-table :data="lookData[2]" border style="width: 100%;margin-top: 20px">
        <el-table-column label="升级说明" prop="description"/>
      </el-table>

      <el-table :data="lookData[3]" border style="width: 100%;margin-top: 20px">
        <el-table-column label="更新记录" prop="record"/>
      </el-table>

      <el-button>下载升级文件</el-button>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {reactive, ref} from "vue"
import './upgradeFile.less'
import {ElMessage} from "element-plus";
import TreeTransfer from '../../components/treeTransfer/treeTransfer'

// 最上面选择器列表
let idList = ref(['aaa', 'bbb', 'ccc', 'ddd'])
// 选择器选择的网关
let gatewayValue = ref('')

// 升级文件表格--------------------------------------------------------
let upgradeFileData = ref([
  {
    idx: 1,
    versionNumber: '2.1.1',
    fileName: 'ZZK2.1.1升级文件',
    upgradeDescription: '修改bug',
    validationState: '未验证',
    releaseStatus: '发布',
  },
  {
    idx: 2,
    versionNumber: '2.1.1',
    fileName: 'ZZK2.1.1升级文件',
    upgradeDescription: '修改bug',
    validationState: 'PID+别名',
    releaseStatus: '未发布',
  },
  {
    idx: 3,
    versionNumber: '2.1.1',
    fileName: 'ZZK2.1.1升级文件',
    upgradeDescription: '修改bug',
    validationState: '未验证',
    releaseStatus: '发布',
  }
])


// 新建编辑升级文件的相关事件与数据******************************************************
// 新建或编辑升级文件弹窗
let upgradeFileAlert = ref(false)
// 新建或编辑升级文件的弹窗表单
let upgradeFileForm = ref({
  password: '',
  code: '',
  timeInterval: '',
  frequency: '',
  maximum: '',
  versionNumber: '',
  upgradeDescription: '',
  updateRecord: '',
  hardwareRange: '',
  softwareRange: '',
  upload: '',
})

// 新建升级文件
const createUpgradeFile = function () {
  // 每次新建把表单清空
  upgradeFileForm.value = {
    password: '',
    code: '',
    timeInterval: '',
    frequency: '',
    maximum: '',
    versionNumber: '',
    upgradeDescription: '',
    updateRecord: '',
    hardwareRange: '',
    softwareRange: '',
    upload: ''
  }
  upgradeFileAlert.value = true
}
// 编辑升级文件
const editUpgradeFile = function (scope) {
  // 获取本行信息
  console.log(scope.row.name)
  upgradeFileAlert.value = true
}

// 新建或编辑升级文件提交
const upgradeFileFormSubmit = function () {
  console.log(upgradeFileForm.value)
  upgradeFileAlert.value = false
}


// 验证相关事件与数据 ******************************************************
// 验证弹窗是否展示
let verificationAlert = ref(false)
// 验证表单数据
let verificationForm = ref({
  gatewayPID: '',
  address: ''
})
// 打开验证弹窗
const verificationFun = function (scope) {
  verificationAlert.value = true
  verificationForm.value = {
    gatewayPID: '',
    address: ''
  }
}
// 点击进行验证
const verificationFunClick = function () {
  verificationAlert.value = false
}


// 升级管理相关事件与数据 ******************************************************
// 是否展示升级管理的弹窗--------------------------------
let upgradeManagementAlert = ref(false)
// 点击展示升级管理
const upgradeFun = function (scope) {
  upgradeManagementAlert.value = true
}
// 是否展示确认发布的弹窗--------------------------------
let confirmUpgradeAlert = ref(false)

// 是否展示升级完成的弹窗--------------------------------
let completeUpgradeAlert = ref(false)
// 升级完成的表格数据
let upgradeResultData = ref([
  {
    PIDName: '123 aaa',
    result: '已完成'
  },
  {
    PIDName: '123 aaa',
    result: '已下发'
  },
  {
    PIDName: '123 aaa',
    result: '未下发'
  }
])
// 点击展示升级完成弹窗
const confirmUpgradeSubmit = function () {
  upgradeManagementAlert.value = false
  confirmUpgradeAlert.value = false
  completeUpgradeAlert.value = true
}
// 点击取消升级
const cancelUpgrade = function () {
  completeUpgradeAlert.value = false
}


// 升级记录相关事件与数据 ******************************************************
// 是否展示升级记录的弹窗
let recordAlert = ref(false)
// 是否展示升级记录详情弹窗
let recordDetailAlert = ref(false)
// 升级记录的表格数据
let recordData = ref([
  {
    idx: 1,
    upgradeTime: '',
    gatewayId: '',
    gatewayAddress: '',
    upgradeSoft: '',
    upgradeHard: '',
    upgradeUser: '',
  },
  {
    idx: 2,
    upgradeTime: '',
    gatewayId: '',
    gatewayAddress: '',
    upgradeSoft: '',
    upgradeHard: '',
    upgradeUser: '',
  },
  {
    idx: 3,
    upgradeTime: '',
    gatewayId: '',
    gatewayAddress: '',
    upgradeSoft: '',
    upgradeHard: '',
    upgradeUser: '',
  }
])
// 点击展示升级记录
const recordFun = function (scope) {
  recordAlert.value = true
}
// 点击查看升级记录
const lookRecord = function (scope) {
  recordDetailAlert.value = true
}

// 查看相关事件与数据 ******************************************************
// 查看弹窗是否展示
let lookAlert = ref(false)

// 查看弹窗的表格数据
let lookData = ref([
  [{
    password: '',
    codePeople: '',
    timeInterval: '',
    frequency: '',
    byteNumber: '',
    pass: '',
    code: '',
    versionNumber: '',
  }], [{
    soft: '',
    hard: '',
  }], [{
    description: ''
  }], [{
    record: ''
  }]
])

// 点击打开查看弹窗
const lookFun = function (scope) {
  lookAlert.value = true
}

// 删除升级文件的相关事件与数据******************************************************
// 是否展示删除升级文件弹窗
let delUpgradeFileAlert = ref(false)
// 删除升级文件
const delUpgradeFile = function (scope) {
  delUpgradeFileAlert.value = true
}
// 确定删除升级文件
const delUpgradeFileFun = function () {
  delUpgradeFileAlert.value = false
  ElMessage({
    message: '删除成功',
    type: 'success',
  })
}


</script>
